export const getCommaSeparatedNumber = (amount) => {
    return new Intl.NumberFormat("en-US").format(amount);
};

export const isAvailable = (a = "", b = "") => {
    let validFormatA = a.toString();
    let validFormatB = b.toString();

    if (!validFormatA || !validFormatB) return;

    return validFormatA?.includes(validFormatB);
};

export const getFormattedAmountWithCurrency = (amount, currency = "AED") => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency || "USD",
        minimumFractionDigits: 0
    });
    return formatter.format(amount);
};

export const escapeHTMLElementsFromMessage = (message) => {
    //todo places in util helper
    var htmlEscapes = {
        "<": "&lt;",
        ">": "&gt;"
    };
    // Regex containing the keys listed immediately above.
    var htmlEscaper = /[<>]/g;
    // Escape a string for HTML interpolation.
    return message.replace(htmlEscaper, function (match) {
        return htmlEscapes[match];
    });
};

export const getObjectByKeys = (arr, key = "Id", deleteKey = null, addKeys = null) => {
    let obj = {};
    arr.forEach((val) => {
        obj[val[key]] = val;
        if (deleteKey) {
            delete obj[val[key]][deleteKey];
        }
        if (addKeys) {
            obj[val[key]] = {
                ...obj[val[key]],
                ...addKeys
            };
        }
    });
    return obj;
};

export const getIdsFromData = (data, key = "Id") => {
    return data.map((item) => item[key]);
};

export const objectContainsKey = (object, key) => {
    return typeof object === "object" && object && object[key] !== undefined;
};

export const getPhoneNumber = (data) => {
    if (data && data.CountryCode && data.PhoneNumber) {
        return `${data.CountryCode} ${data.PhoneNumber}`;
    }
    return "-";
};

export const prepareTableColumnState = (columnData) => {
    let obj = {};
    Object.keys(columnData).forEach((key) => {
        obj[columnData[key]] = {
            title: columnData[key],
            isShown: true
        };
    });

    return obj;
};

export const getObjectFromKeyValue = (
    arr = [],
    key = "",
    subKey = "",
    getValueKey = "",
    value = ""
) => {
    if (!arr?.length) return;
    if (!arr.find((item) => item[key]).length) return;

    return arr.find((item) => item[key][subKey] == value)[getValueKey];
};

export const removeDuplicatValFromArray = (arr) => {
    if (!arr.length) {
        return;
    }

    return [...new Set(arr)];
};

export const replaceStrings = (str, keyToFind, keysToReplaceObj = {}) => {
    return str.replace(keyToFind, (matched) => {
        return keysToReplaceObj[matched];
    });
};

export const joinArrayItems = (list) => {
    if (Array.isArray(list)) {
        return list ? list.join(", ") : undefined;
    }
};
export const hasData = (obj) => Boolean(obj && Object.keys(obj).length);

export const removeSpaceUnderscoreCharacterFromString = (str = "") => {
    if (!str) return;
    let data = str;
    let newStr = data.replace(/[^a-zA-Z.]/g, ""); // remove non-letter characters, except period
    return `${newStr}`;
};

export const getSelectBoxOptionsFromConstants = (obj = {}) => {
    let keys = Object.keys(obj);

    if (!keys?.length) return;

    let options = keys.map((item, index) => ({
        id: `${index}_${item}`,
        label: obj.getDisplayTextKey(obj[item]),
        value: obj[item]
    }));

    return options;
};

export const truncateString = (string = "", maxLength) => {
    if (!string || !maxLength) return;
    return string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;
};

export const getTwoStrConcat = (str1 = "", str2 = "", separator = "-") => {
    return str1 + separator + str2;
};

export const formatKey = (key) => {
    // Split camelCase and capitalize each word
    const words = [];
    let currentWord = "";

    for (let char of key) {
        if (char === char.toUpperCase() && currentWord.length > 0) {
            words.push(currentWord);
            currentWord = char; // start a new word with the uppercase character
        } else {
            currentWord += char; // append character to the current word
        }
    }
    words.push(currentWord); // push the last word
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
};

export const replaceUnderscoreWithSpace = (str) => {
    return str.replace(/_/g, " ");
};

export const HOME_MAINTENANCE = "HomeMaintenance";

export const renderCategoryName = (category) => {
    if (category == HOME_MAINTENANCE) {
        return "HouseholdUpkeep";
    }
    return category || "";
};
