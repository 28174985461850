var Config = {
    LIMIT: 10,
    PERSIST_SECRET_KEY: "APP_REDUX_PERSIST_STORE_KEY",
    ENVIRONMENT: "DEVELOPMENT",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "APP_API_URL",
            GOOGLE_API_KEY: "APP_GOOGLE_API_KEY"
        },
        DEVELOPMENT: {
            API_URL: "https://api-ovadev.kodefuse.com/v1",
            GOOGLE_API_KEY: "AIzaSyA1QhGFu0xIKAO0n146vl7PTf2dCk_hPNQ"
        },
        STAGING: {
            // API_URL: "http://34.199.30.131:3025/v1",
            API_URL: "https://api-ovaqa.kodefuse.com/v1",
            GOOGLE_API_KEY: "AIzaSyA1QhGFu0xIKAO0n146vl7PTf2dCk_hPNQ"
        },
        DEMO: {
            API_URL: "https://demo-api-pured.kodefuse.com",
            GOOGLE_API_KEY: "AIzaSyA1QhGFu0xIKAO0n146vl7PTf2dCk_hPNQ"
        },
        PRODUCTION: {
            API_URL: "APP_API_URL ",
            GOOGLE_API_KEY: "APP_GOOGLE_API_KEY"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;
